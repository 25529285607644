import React from 'react'

const Contact = () => {
  return (
    <div>
        <div className='container-fluid' style={{backgroundColor:"rgb(235, 203, 203)",paddingBottom:"150px",fontSize:"medium"}}>
    <div className='row mx-5'>
        <div className='col-md-6' style={{marginTop:"250px"}}>
           <h1>book a table please contact us <br></br>and get early discount offer</h1>
           <br></br>
           <p className='p_hero text-center'>Sed autem laudantium dolores. Voluptatem itaque ea <br></br>consequatur eveniet. Eum quas beatae cumque eum quaerat.</p>
           <button className='btn btn-danger' style={{marginLeft:"30vh"}}>Book a Table</button>
        </div>
        <div className='col-md-6' style={{marginTop:"120px"}}>
            <img src='https://bootstrapmade.com/demo/templates/Yummy/assets/img/menu/menu-item-6.png'/>
        </div>
    </div>
</div>
<br></br><br></br><br></br><br></br>


                <h4 className='about_hero' style={{color:"black"}}>CONTACT US</h4>
        <h1>Need Help? <span className='mx-2'>Contact Us </span></h1>
        <p><iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3024.3612811035196!2d-74.0087126243504!3d40.71006263773476!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c25a22a3bda30d%3A0xb89d1fe6bc499443!2sDowntown%20Conference%20Center!5e0!3m2!1sen!2s!4v1704547731571!5m2!1sen!2s" style={{height:"500px" ,width:"1563px",border:"0"}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe></p>
        <br></br><br></br><br></br><br></br>


        <div className='container-fluid py-0'style={{backgroundColor:"gray"}}>
            <div className='row' style={{display:"flex",justifyContent:"center",paddingBottom:"20vh",paddingTop:"20vh"}}>
                <div className='col-md-5'>
                    <input type='text' class="form-control" placeholder='Your Name'/>
                    <br></br>
                    <input type='Email' class="form-control" placeholder='Your Email'/>
                    <br></br>
                    <input type='Message' class="form-control" style={{height:"10vh"}} placeholder='Your Message'/>
                    <br></br>
                    <button type="button" className='btn btn-danger btn-lg mx-5'>Send Message</button>
                </div>
            </div>
            </div>
            <br></br><br></br><br></br><br></br>
    </div>
    

  )
}

export default Contact