import React from 'react'
import Navbar from './Components/Navbar/Navbar'
import About from './Components/About/About'
import Menu from './Components/Menu/Menu'
import Contact from './Components/Contact'
import Footer from './Components/Footer/Footer'
import {BrowserRouter as Router , Routes , Route } from 'react-router-dom'
import Home from './Components/Home'




const App = () => {
  return (
    <>
    <Router>
      <Navbar/>
      <Routes>
        <Route path='/' element={<Home/>} />
        <Route path='About' element={<About/>} />
        <Route path="Menu" element={<Menu/>} />
        <Route path="Contact" element={<Contact/>} />
      </Routes>
      <Footer/>
    </Router>
    </>
  )
}

export default App