import React from 'react';
import './About.css';

const About = () => {
  return (
    <div>

<div className='container-fluid' style={{backgroundColor:"rgb(235, 203, 203)",paddingBottom:"150px",fontSize:"medium"}}>
    <div className='row mx-5'>
        <div className='col-md-6' style={{marginTop:"250px"}}>
           <h1>We are best italian <br></br> food chain in USA </h1>
           <br></br>
           <p className='p_hero text-center'>Sed autem laudantium dolores. Voluptatem itaque ea <br></br>consequatur eveniet. Eum quas beatae cumque eum quaerat.</p>
           <button className='btn btn-danger' style={{marginLeft:"30vh"}}>Book a Table</button>
        </div>
        <div className='col-md-6' style={{marginTop:"120px"}}>
            <img src='https://bootstrapmade.com/demo/templates/Yummy/assets/img/menu/menu-item-4.png'/>
        </div>
    </div>
</div>
<div >
  <br></br><br></br><br></br>


        <h4 className='about_hero' style={{color:"black"}}>ABOUT US</h4>
        <h1>Learn More <span className='mx-2'>About Us</span></h1>
    </div>


    {/* // ====================== */}


    <div className='container-fluid'>
      <div className='row mx-5'>
        <div className='col-md-7'>
          <img src='https://www.glassinfotech.com/assets/about.jpg' style={{height:"120vh"}}/>
          <div className='book_table' style={{marginTop:"-64vh",marginLeft:"30vh"}}>
            <h3 className='f-bold'>Book a Table</h3>
            <span className='fw-bold'>+1 5589 55488 55</span>
          </div>
        </div>
        <div className='col-md-5 '>
          <p className='para_about mx-5'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
          <p className='para_about mx-5'>Ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
          <p className='para_about mx-5'>Duis aute irure dolor in reprehenderit in voluptate velit.</p>
          <p className='para_about mx-5'>Ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate trideta storacalaperda mastiro dolore eu fugiat nulla pariatur.</p>
          <p className='para_about mx-5'>Ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident</p>
          <img className="mx-5" src='https://bootstrapmade.com/demo/templates/Yummy/assets/img/about-2.jpg' style={{height:"40vh",width:"80%"}}/>
        </div>
      </div>
    </div>
    <br></br><br></br><br></br>


    {/* ============= */}

    <div className='container-fluid'>
      <div className='row mx-5'>
        <div className='col-md-3' style={{backgroundColor:"red",color:"white"}}>
          <h1 style={{color:"white",fontWeight:"bold"}}>Why Choose <br></br>Yummy?</h1>
          <p className='para_about mx-5'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Duis aute irure dolor in reprehenderit Asperiores dolores sed et. Tenetur quia eos. Autem tempore quibusdam vel necessitatibus optio ad corporis.</p>
          <button className='btn btn-danger mx-5'>Learn More</button>
        </div>
        <div className='col-md-3 uk-animation-toggle' >
        <div class="uk-card uk-card-default uk-card-body uk-animation-scale-up">
        <img class="uk-border-circle" style={{marginLeft:"12vh"}} width="80" height="85" src="https://bootstrapmade.com/demo/templates/Yummy/assets/img/testimonials/testimonials-2.jpg" alt="Avatar"/>
            <h1 class="uk-card-title text-lg-center" >Corporis voluptates officia eiusmod</h1>
            <p className='text-lg-center' style={{fontSize:"medium"}}>Consequuntur sunt aut quasi enim aliquam quae harum pariatur laboris nisi ut aliquip</p>
        </div>
        </div>
        <div className='col-md-3 uk-animation-toggle'>
        <div class="uk-card uk-card-default uk-card-body uk-animation-scale-up">
        <img class="uk-border-circle" style={{marginLeft:"12vh"}} width="80" height="85" src="https://bootstrapmade.com/demo/templates/Yummy/assets/img/testimonials/testimonials-2.jpg" alt="Avatar"/>
            <h1 class="uk-card-title text-lg-center" >Corporis voluptates officia eiusmod</h1>
            <p className='text-lg-center' style={{fontSize:"medium"}}>Consequuntur sunt aut quasi enim aliquam quae harum pariatur laboris nisi ut aliquip</p>
        </div>
        </div>
        <div className='col-md-3 uk-animation-toggle'>
        <div class="uk-card uk-card-default uk-card-body uk-animation-scale-up">
        <img class="uk-border-circle" style={{marginLeft:"12vh"}} width="80" height="85" src="https://bootstrapmade.com/demo/templates/Yummy/assets/img/testimonials/testimonials-2.jpg" alt="Avatar"/>
            <h1 class="uk-card-title text-lg-center" >Corporis voluptates officia eiusmod</h1>
            <p className='text-lg-center' style={{fontSize:"medium"}}>Consequuntur sunt aut quasi enim aliquam quae harum pariatur laboris nisi ut aliquip</p>
        </div>
        </div>
      </div>
    </div>
    </div>



  )
}

export default About