import React  from 'react';
import './Menu.css';
import Events from '../Events/Events';

const Menu = () => {
    



  return (
    <div>
<div className='container-fluid' style={{backgroundColor:"rgb(235, 203, 203)",paddingBottom:"150px",fontSize:"medium"}}>
    <div className='row mx-5'>
        <div className='col-md-6' style={{marginTop:"250px"}}>
           <h1>We have the best Menu <br></br>  for Desi and italian Dishes <br></br> </h1>
           <br></br>
           <p className='p_hero text-center'>Sed autem laudantium dolores. Voluptatem itaque ea <br></br>consequatur eveniet. Eum quas beatae cumque eum quaerat.</p>
           <button className='btn btn-danger' style={{marginLeft:"30vh"}}>Book a Table</button>
        </div>
        <div className='col-md-6' style={{marginTop:"120px"}}>
            <img src='https://bootstrapmade.com/demo/templates/Yummy/assets/img/menu/menu-item-2.png'/>
        </div>
    </div>
</div>
<div >
</div>
<br></br><br></br><br></br><br></br>

        
        <div >
        <h4 className='about_hero' style={{color:"black"}}>OUR MENU</h4>
        <h1>Check Our <span className='mx-2'>Yummy Menu </span></h1>
    </div>

    {/* ========= */}
    <div className='btn-group'>
        <a href='#' className='mx-3' >starters</a>
        <a href='#' className='mx-3' >Breakfest</a>
        <a href='#' className='mx-3'>Lunch</a>
        <a href='#' className='mx-3'>Dinner</a>
    </div>
    <br></br><br></br>
    <div >
        <h4 className='about_hero' style={{color:"black"}}>MENU</h4>
        <h1><span style={{marginTop:"-25px"}}>Starters</span></h1>
    </div>


    {/* ==========img====== */}
    <div className='container'>
        <div className='row'>
            <div className='col-md-4'>
                <img src='https://bootstrapmade.com/demo/templates/Yummy/assets/img/menu/menu-item-1.png'/>
                <h2 className='text-center fw-bold' style={{color:"black"}}>Magnam Tiste</h2>
                <h3 className='text-center' style={{color:"gray"}}>Lorem, deren, trataro, filede, nerada</h3>
                <h3 className='text-center fw-bold' style={{color:"red"}}>$5.95</h3> 
            </div>
            <div className='col-md-4'>
                <img src='https://bootstrapmade.com/demo/templates/Yummy/assets/img/menu/menu-item-2.png'/>
                <h2 className='text-center fw-bold' style={{color:"black"}}>Aut Luia</h2>
                <h3 className='text-center' style={{color:"gray"}}>Lorem, deren, trataro, filede, nerada</h3>
                <h3 className='text-center fw-bold' style={{color:"red"}}>$14.95</h3> 
            </div>
            <div className='col-md-4'>
                <img src='https://bootstrapmade.com/demo/templates/Yummy/assets/img/menu/menu-item-3.png'/>
                <h2 className='text-center fw-bold' style={{color:"black"}}>Est Eligendi</h2>
                <h3 className='text-center' style={{color:"gray"}}>Lorem, deren, trataro, filede, nerada</h3>
                <h3 className='text-center fw-bold' style={{color:"red"}}>$8.95</h3> 
            </div>
        </div>
        
    </div>


    <div className='container'>
        <div className='row'>
            <div className='col-md-4'>
                <img src='https://bootstrapmade.com/demo/templates/Yummy/assets/img/menu/menu-item-4.png'/>
                <h2 className='text-center fw-bold' style={{color:"black"}}>Eos Luibusdam</h2>
                <h3 className='text-center' style={{color:"gray"}}>Lorem, deren, trataro, filede, nerada</h3>
                <h3 className='text-center fw-bold' style={{color:"red"}}>$12.95</h3> 
            </div>
            <div className='col-md-4'>
                <img src='https://bootstrapmade.com/demo/templates/Yummy/assets/img/menu/menu-item-5.png'/>
                <h2 className='text-center fw-bold' style={{color:"black"}}>Eos Luibusdam</h2>
                <h3 className='text-center' style={{color:"gray"}}>Lorem, deren, trataro, filede, nerada</h3>
                <h3 className='text-center fw-bold' style={{color:"red"}}>$12.95</h3> 
            </div>
            <div className='col-md-4'>
                <img src='https://bootstrapmade.com/demo/templates/Yummy/assets/img/menu/menu-item-6.png'/>
                <h2 className='text-center fw-bold' style={{color:"black"}}>Laboriosam Direva</h2>
                <h3 className='text-center' style={{color:"gray"}}>Lorem, deren, trataro, filede, nerada</h3>
                <h3 className='text-center fw-bold' style={{color:"red"}}>$9.95</h3> 
            </div>
        </div>
    </div>
    <br></br><br></br><br></br><br></br><br></br><br></br>


    <Events/>
    </div>
  )
}

export default Menu