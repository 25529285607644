import React from 'react';
import './Navbar.css';
import { Link } from 'react-router-dom';



const Navbar = () => {
  return (
    <div>
        <nav class="navbar navbar-expand-lg navbar-light navbar sticky-top" style={{backgroundColor:"white"}}>
  <div class="container">
    <a class="navbar-brand" href="#"><i class="fa-solid fa-restroom"></i> Yummy</a>
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav mx-auto mx-2 mb-lg-0">
        <li class="nav-item">
          <Link class="nav-link" aria-current="page"  to="/Home">Home</Link>
        </li>
        <li class="nav-item">
          <Link class="nav-link" aria-current="page"  to="/About">About</Link>
        </li>
        <li class="nav-item">
          <Link class="nav-link" aria-current="page" to="/Menu">Menu</Link>
        </li>
        <li class="nav-item">
          <Link class="nav-link" aria-current="page" to="/Contact">Contact</Link>
        </li>
      </ul>
      <button className='btn btn-danger'>Book a table</button>
    </div>
  </div>
</nav>
    </div>






  )
}

export default Navbar