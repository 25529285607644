import React from 'react'

const Home = () => {
  return (
    <div className='container-fluid' style={{backgroundColor:"rgb(235, 203, 203)",paddingBottom:"150px",fontSize:"medium"}}>
    <div className='row mx-5'>
        <div className='col-md-6' style={{marginTop:"250px"}}>
           <h1>Enjoy Your Healthy <br></br>Delicious Food</h1>
           <br></br>
           <p className='p_hero text-center'>Sed autem laudantium dolores. Voluptatem itaque ea <br></br>consequatur eveniet. Eum quas beatae cumque eum quaerat.</p>
           <button className='btn btn-danger' style={{marginLeft:"30vh"}}>Book a Table</button>
        </div>
        <div className='col-md-6' style={{marginTop:"120px"}}>
            <img src='https://bootstrapmade.com/demo/templates/Yummy/assets/img/hero-img.png'/>
        </div>
    </div>
</div>
  )
}

export default Home