import React from 'react';
import './Footer.css'

const Footer = () => {
  return (
    <div>
        <div className='container-fluid' style={{backgroundColor:'black',paddingTop:"20vh",paddingBottom:"20vh",marginTop:"20vh"}}>
            <div className='row'>
                <div className='col-md-3'>
                    <h3 className='fw-bold text-white'><i class="fa-solid fa-location-dot"></i> Address</h3>
                    <h5 className='text-white'>A108 Adam StreetYork <br></br>NY 535022 - US</h5>
                </div>
                <div className='col-md-3'>
                <h3 className='fw-bold text-white'><i class="fa-solid fa-utensils"></i> Reservations</h3>
                <h5 className='text-white'>Phone: +1 5589 55488 55 <br></br>Email: info@example.com</h5>
                </div>
                <div className='col-md-3'>
                <h3 className='fw-bold text-white'><i class="fa-solid fa-clock"></i> Opening Hours</h3>
                <h5 className='text-white'>Opening Hours Mon-Sat: 11AM - 23PM <br></br> Sunday: Closed</h5>
                </div>
                <div className='col-md-3'>
                <h3 className='fw-bold text-white'><i class="fa-solid fa-share-nodes"></i> Follow Us</h3>
                <h3>
                    <i class="fa-brands fa-instagram" style={{color:"white"}}></i>
                    <i class="fa-brands fa-facebook mx-2" style={{color:"white"}}></i>
                    <i class="fa-brands fa-twitter mx-2" style={{color:"white"}}></i>
                    <i class="fa-brands fa-google mx-2" style={{color:"white"}}></i>
                    </h3>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Footer