import React from 'react';
import './Events.css';

const Events = () => {
  return (
    <div className='container' style={{paddingTop:"10vh"}}>
      <div className='row'>
      <div className='col-md-4 uk-animation-toggle uk-animation-scale-up' >
        <div class="uk-card uk-card-default uk-card-body uk-animation-scale-up">
        <img className="event-img" style={{width:"39vh"}} src="https://images.pexels.com/photos/2102934/pexels-photo-2102934.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" alt="Avatar"/>
            <h1 class="uk-card-title text-lg-center fw-bold" >Walter White</h1>
            <p className='text-lg-center' style={{fontSize:"medium"}}>Master Chef</p>
            <p className='text-lg-center' style={{fontSize:"medium"}}>Velit aut quia fugit et et. Dolorum ea voluptate vel tempore tenetur ipsa quae aut. Ipsum exercitationem iure minima enim corporis et voluptate.</p>

        </div>
        </div>
        <div className='col-md-4 uk-animation-toggle uk-animation-scale-up' >
        <div class="uk-card uk-card-default uk-card-body uk-animation-scale-up">
        <img style={{width:"44vh"}}src="https://images.pexels.com/photos/15656547/pexels-photo-15656547/free-photo-of-a-chef-preparing-a-meal.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" alt="Avatar"/>
            <h1 class="uk-card-title text-lg-center fw-bold" >Sarah Jhonson</h1>
            <p className='text-lg-center' style={{fontSize:"medium"}}>Patissier</p>
            <p className='text-lg-center' style={{fontSize:"medium"}}>Quo esse repellendus quia id. Est eum et accusantium pariatur fugit nihil minima suscipit corporis. Voluptate sed quas reiciendis animi neque sapiente.</p>

        </div>
        </div>
        <div className='col-md-4 uk-animation-toggle uk-animation-scale-up' >
        <div class="uk-card uk-card-default uk-card-body uk-animation-scale-up">
        <img style={{width:"41vh"}} src="https://images.pexels.com/photos/3814446/pexels-photo-3814446.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" alt="Avatar"/>
            <h1 class="uk-card-title text-lg-center fw-bold" >William Anderson</h1>
            <p className='text-lg-center' style={{fontSize:"medium"}}>Cook</p>
            <p className='text-lg-center' style={{fontSize:"medium"}}>Vero omnis enim consequatur. Voluptas consectetur unde qui molestiae deserunt. Voluptates enim aut architecto porro aspernatur molestiae modi.</p>

        </div>
        </div>
      </div>
      <br></br><br></br><br></br><br></br><br></br>
    </div>
  )
}

export default Events